import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table, Tooltip } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getCurrentOrders } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { valueCell } from "../../components/ValueCell/ValueCell";
import { CSVLink } from "react-csv";
import SortableTable from "../../components/SortableTable/SortableTable";

import css from "../Dashboard/Dashboard.module.css";

export const CurrentOrders = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { currentOrders, getCurrentOrdersInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = currentOrders || {};

  useEffect(() => {
	dispatch(
	  getCurrentOrders({
		upc,
	  }),
	);
  }, [upc]);

  const columns = [
	/* 
	{
	  title: "UPC",
	  editable: true,
	  dataIndex: "UPC",
	  width: 250,
	  sorter: true, 
	  align: "left",
		showSorterTooltip: false,
	},
	*/
	{
		title: (
			<Tooltip title="Catalogue Number">
				<div>Catalogue Number</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "Catalogue_Number",
	  sorter: true,
	  width: 200,
	  align: "left",
		showSorterTooltip: false,
	},

	{
	  title: (
			<Tooltip title="Store / Branch name">
				<div>Account Name</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "Account_Name",
	  width: 250,
	  sorter: true, 
	  align: "left",
		showSorterTooltip: false,
	},
	/*
	{
	  title: "Account Type",
	  editable: true,
	  dataIndex: "Account_Type",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Account_Type:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Order Date",
	  editable: true,
	  dataIndex: "Order_Date",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Order_Date:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  render: (record) => dateCell(record)
	},
	*/
	{
	  title: (
		<Tooltip title="Total current presales (excluding Consignment / FOC)">
		  <div>New Release Held</div>
		</Tooltip>
	  ),
	  editable: true,
	  dataIndex: "New_Release_Held",
	  sorter: true,
	  width: 150,
	  align: "left",
	  showSorterTooltip: false,
	  render: (record) => valueCell(record)
	},
	{
	  title: (
		<Tooltip title="Total orders waiting to ship (excluding Consignment / FOC)">
			<div>Orders</div>
		</Tooltip>
	  ),
	  editable: true,
	  dataIndex: "Orders",
	  width: 100,
	  sorter: true,
	  align: "left",
		showSorterTooltip: false,
	  render: (record) => valueCell(record)
	},
	/*
	{
	  title: "Pre-Orders",
	  editable: true,
	  dataIndex: "Pre_Orders",
	  width: 100,
	  sorter: true,
	  align: "left",
		showSorterTooltip: false,
	  render: (record) => valueCell(record)
	},
	*/	

	{
		title: (
			<Tooltip title="Total Consignment and FOC orders waiting to ship">
				<div>FOC Cons. Orders</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "FOC_Consignment_Orders",
	  width: 100,
	  sorter: true, 
	  align: "left",
		showSorterTooltip: false,
	  render: (record) => valueCell(record)
	}
  ];

  return (
		<Flex className={""} vertical>
			<SectionHeader title="Current Orders Summary">
				<CSVLink data={rows || []} filename="current_orders.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<SortableTable
				components={{
					header: {
						row: HeaderCell,
					},
				}}
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={rows}
				pagination={{
					position: ["bottomRight"],
					responsive: true,
					total: totalPages,
					hideOnSinglePage: true,
					showSizeChanger: true,
				}}
				loading={getCurrentOrdersInProgress}
				style={{ marginTop: 10 }}
				scroll={{ x: "100%" }}
			/>
		</Flex>
  );
};
