import React, { useState } from "react";
import { Checkbox, Button } from "antd";
import css from "./FieldSelector.module.css";

export const FieldSelector = ({ columns, onApply }) => {
  const [selectedColumns, setSelectedColumns] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.dataIndex]: true }), {})
  );

  const [isVisible, setIsVisible] = useState(false);
  const allSelected = Object.values(selectedColumns).every(Boolean);

  const handleCheckboxChange = (dataIndex) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [dataIndex]: !prev[dataIndex],
    }));
  };

  const handleSelectAll = () => {
    const allSelected = Object.values(selectedColumns).every(Boolean);
    const newSelection = columns.reduce(
      (acc, col) => ({ ...acc, [col.dataIndex]: !allSelected }),
      {}
    );
    setSelectedColumns(newSelection);
  };

  return (
    <div className={css.selectorHolder}>
      <Button onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? "Hide Field Selector" : "Show Field Selector"}
      </Button>

      {isVisible && (
        <div className={css.selectorDropdown}>
          <div className={css.selectorHeader}>
            <Button onClick={handleSelectAll}>
              {allSelected ? "Unselect All" : "Select All"}
            </Button>
            <Button
              type="primary"
              onClick={() => onApply(selectedColumns)}
              style={{ marginLeft: "10px" }}
            >
              Apply
            </Button>
          </div>
          <div className={css.columnsContainer}>
            {columns.map((col) => (
              <div key={col.dataIndex} className={css.columnsItem}>
                <Checkbox
                  checked={selectedColumns[col.dataIndex]}
                  onChange={() => handleCheckboxChange(col.dataIndex)}
                >
                  {col.title}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
