import React from "react";
import css from "./SectionHeader.module.css";
import classNames from "classnames";

export const SectionHeader = ({ title, children = null, titleClassName = null }) => {
  return (
    <div className={css.headerContainer}>
      <h2 className={classNames(css.title, titleClassName)}>{title}</h2>
      {children}
    </div>
  );
};
