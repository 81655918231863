import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Button, DatePicker, Select } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getSearch, getFormats } from "./searchSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { SearchSelect } from "../../components/SearchSelect/SearchSelect";

import css from "../Dashboard/Dashboard.module.css";
//import formats from '../../constants/formats';
const { RangePicker } = DatePicker;

export const SearchBox = ({onApply, onReset}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectRef = React.useRef(null);

  const [search, setSearch] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState([]);
  const [format, setFormat] = React.useState([]);
  const [range, setRange] = React.useState({start: null, end: null});
  
  useEffect(() => {
	dispatch(getFormats()); 
  }, []);
  
  const { formats, getFormatsInProgress } =
	useSelector((state) => state?.search) || {};

  
  const reset = () => {
  	form.resetFields();
	setSearch([]);
	setSearchValues([]);
	setFormat([]);
	setRange({start: null, end: null});
	// onApply({searchValues: [], format: [], range: {start: null, end: null}});
	onReset();
  }
  
  const isFilterSelected = () => {
	  if(search.length == 0 && format.length == 0 && range.start == null && search.end == null)
	  	return false;
	  return true;
  }
  
  const filterAndAdd = (results) => {
	 const addEntities = ['catalog', 'UPC']; 
	 let newEntitiesAdded = false;
	 let filteredResults = [], newSearch = [...search], newSearchValues = [...searchValues];
	 for(let row of results) {
		if(addEntities.includes(row.entity)) {
			if(!newSearch.includes(row.value)) {
				newEntitiesAdded = true;
				newSearch.push(row.value);
				newSearchValues.push(row);
			}
		}
		else {
			filteredResults.push(row);
		}
	 } 
	 if(newEntitiesAdded) {
		setSearch(newSearch);
		setSearchValues(newSearchValues); 
		form.setFieldsValue({
			search: newSearch
		})
		if (selectRef.current) {
			selectRef.current.blur();
		}
	 }
	 return filteredResults;
  }
  
  const getFormattedSearch = async(query) => {
	const values = await dispatch(getSearch({query}));
	const fullResults = Array.isArray(values.payload) ? values.payload.map(result=>({
		label: `${result.name} (${result.entity})`,
		value: `${result.name} (${result.entity})`,
		term: result.name,
		entity: result.entity
	})) : [];  
	return filterAndAdd(fullResults);
  }
  
  const handleDateChange = (dates) => {
  	if(dates) {
  		const [start, end] = dates || [];
  		setRange({start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')});
  	} 
	else {
		setRange({start: null, end: null});
	}
  };
  
  const prepareSearch = () => {
	  const flatFormat = format.map(line=>line.split(',')).flat();
	  onApply({searchValues, format: flatFormat, range})
  }
  
  const initialDates = [range.start, range.end];
  //const formatOptions = formats.map(f=>{f.value = String(f.value); return f;}).sort((a, b) => b.label < a.label);

  return (
	<Flex className={""} vertical>
	  <Form
		name="searchForm"
		style={{ marginTop: 10, marginBottom: 0 }}
		initialValues={{ remember: true }}
		autoComplete="off"
		form={form}
	  >
		<Row gutter={16} className={css.searchFormContainer}>
		  <Col xs={24} md={24} lg={12} xxl={10} className={css.formItem}>
				<label htmlFor="search">Search:</label>
				<Form.Item name="search">
					<SearchSelect
						mode="multiple"
						value={search}
						placeholder="Search by Catalogue Number, UPC, Label, Artist and Title"
						fetchOptions={getFormattedSearch}
						onChange={(newValue, selected) => {
							setSearch(newValue);
							setSearchValues(selected);
						}}
						style={{
							width: '100%',
						}}
						selectRef={selectRef}
						//className="select-search"
					/>
				</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={6} className={css.formItem}>
				<label htmlFor="range">Release Date:</label>
		  	<Form.Item name="range">
		  		<RangePicker format="DD-MM-YYYY"
			  		size={"large"}
			  		value={initialDates}
			  		onChange={handleDateChange}
		  		/>
		  	</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={4} className={css.formItem}>
				<label htmlFor="format">Format:</label>
				<Form.Item name="format">
					<Select
						mode="multiple"
						allowClear
						options={formats}
						onChange={setFormat}
						placeholder="Formats"
					/>
				</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={4} className={css.actionsWrapper}>
				{isFilterSelected() && <Button type="default" htmlType="submit" onClick={()=>reset()}>
					Clear Selection
				</Button>}
				<Button type="primary" htmlType="submit" onClick={()=>prepareSearch()}>
					{isFilterSelected() ? 'Apply' : 'Load All'}
				</Button>

		  </Col>
		</Row>
	  </Form>
	</Flex>
  );
};
