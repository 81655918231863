import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table, Radio, Space, Tooltip, DatePicker } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getSalesSummary } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { valueCell } from "../../components/ValueCell/ValueCell";
import SortableTable from "../../components/SortableTable/SortableTable";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { Goods } from "../GoodsIn/GoodsIn";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";
import css from "../Dashboard/Dashboard.module.css";

const { RangePicker } = DatePicker;

export const SalesSummary = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [type, setType] = useState("type");
  const [sortDirection, setSortDirection] = useState("");
  const [range, setRange] = useState({
  	start: dayjs().subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD'),
	end: dayjs().format('YYYY-MM-DD'), 
  });

  const [showNotification, setShowNotification] = useState(false);
  
  const { salesSummary, getSalesSummaryInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = salesSummary || {};

  useEffect(() => {
	dispatch(
	  getSalesSummary({
		upc,
		type,  
		start: range.start,
		end: range.end
	  }),
	);
  }, [upc, type, range]);
  
  const handleDateChange = (dates) => {
	  let start, end;
	  if(!dates) {
		start = dayjs().subtract(1, 'week').add(1, 'day');
		end = dayjs();
	  }
	  else {
	  	[start, end] = dates || [];
  	  }
	  setRange({start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')});
  };


  const columns = [
	/*  
	{
		title: "UPC",
		showSorterTooltip: false,
		editable: true,
		dataIndex: "UPC",
		width: 250,
		sorter: true,
		align: "left",
  	},
	*/
	{
	  title: (
			<Tooltip title="Catalogue Number">
				<div>Catalogue Number</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "Catalogue_Number",
	  sorter: true,
	  width: 200,
	  align: "left",
		showSorterTooltip: false,
	},
  
	{
		title: (
			<Tooltip title="Store / Branch name">
				<div>Account Name</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Account_Name",
	  width: 250,
	  sorter: true,
	  align: "left",
	  hide: type == 'type'
	},
	{
		title: (
        <Tooltip title="Classification of the account (e.g., Indie, Export, Chain store).">
          <div>Account Type</div>
        </Tooltip>
      ),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Account_Type",
	  width: 250,
	  sorter: true,
	  align: "left",
	  hide: type == 'name'
	},
	{
		title: (
        <Tooltip title="Total shipments to date (excluding Consignment / FOC)">
          <div>Sold Units to date</div>
        </Tooltip>
      ),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Sold_Units",
	  width: 100,
	  sorter: true,
	  align: "left",
	  render: (record) => valueCell(record)
	},
	{
		title: (
        <Tooltip title="Total shipments to Free-of-Charge (FOC) or promotional accounts.">
          <div>FOC Cons. Ship to date</div>
        </Tooltip>
      ),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "FOC_Consignment_Ship_To_Date",
	  width: 100,
	  sorter: true,
	  align: "left",
	  render: (record) => valueCell(record)
	},
	{
		title: (
			<Tooltip title="Total returns credited previous day / may not have been put away yet">
				<div>Returns to date</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Returns_To_Date",
	  width: 100,
	  sorter: true,
	  align: "left",
	  render: (record) => valueCell(record)
	},	
  ].filter(column=>!column.hide);

	const salesTitle = <>Sales by Account <span className="capitalize">{type}</span></>;
	const initialDates = [dayjs(range.start), dayjs(range.end)];
  
	return  (
		<>
		<div className="ibox-holder">
			<Flex className={""} vertical>
				<SectionHeader title="Sales Summary" titleClassName="mainSectionHeader">
				{/*
					<CSVLink data={rows || []} filename="sales_summary.csv">
						<IconDownload />
					</CSVLink>
				*/}	
				</SectionHeader>
			</Flex>
		</div>
		<div className="ibox-holder">
			<Goods upc={upc} />
		</div>
		<div className="ibox-holder">
			<CurrentOrders upc={upc} />
		</div>
		<div className="ibox-holder">
			<div>
				<SectionHeader title={salesTitle}>
					<CSVLink data={rows || []} filename="sales_by_account.csv">
						<IconDownload />
					</CSVLink>
				</SectionHeader>
				<div className={css.sectionDatepickerFilterHolder}>
				{/*
					<RangePicker format="DD-MM-YYYY"
						size={"large"}
						value={initialDates}
						onChange={handleDateChange}
					/>
				*/}	
					<Radio.Group value={type} onChange={(e)=>setType(e.target.value)}>
						<Radio.Button value="type">Account Type</Radio.Button>
						<Radio.Button value="name">Account Name</Radio.Button>
					</Radio.Group>
				</div>
				<SortableTable
					components={{
						header: {
							row: HeaderCell,
						},
					}}
					columns={columns}
					rowKey={(record) => record.id}
					dataSource={rows}
					pagination={{
						position: ["bottomRight"],
						responsive: true,
						total: totalPages,
						pageSize: 10,
						hideOnSinglePage: true,
						showSizeChanger: false,
					}}
					loading={getSalesSummaryInProgress}
					style={{ marginTop: 10 }}
					scroll={{ x: "100%" }}
					tableLayout="fixed"
				/>
			</div>
			{/* <Flex className={""} vertical>
			</Flex> */}
		</div>
		</>
  );
};
