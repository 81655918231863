import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import { checkUserRole } from '../../utils/helpers'

export const PrivateRoute = ({ element, adminOnly, forgotPassword }) => {
  const { user, isAuthenticated } = useAuth();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const role = currentUser?.role;

  if (adminOnly) {
    if ((user || isAuthenticated) && checkUserRole(role,  "admin|label_admin")) {
      return element;
    } else {
      return <Navigate to="/dashboard" />;
    }
  } else {
    if (user || isAuthenticated) {
      return element;
    } else if (!user && forgotPassword) {
      return element;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

