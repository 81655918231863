const storeDistro = [
  'Consignment',
  'FOC',
  'Sale'
]

const grouping = [
  'Amazon UK',
  'Cargo Mailorder',
  'Chains / Other',
  'Distributor - Export',
  'FOC',
  'Fopp',
  'HMV',
  'Indie Store - Ireland',
  'Indie Store - UK',
  'Label Purchase',
  'Online',
  'Wholesaler'
]

const exportDomestic = [
  'Export',
  'Domestic'
]

const source = [
  'Utopia', 
  'PIAS'
]

const wrapOption = (values) => values.map(value=>({key: value, value}))
const wrapLabel = (values) => values.map(value=>({label: value, value}))

export const storeDistroOptions = wrapOption(storeDistro)
export const groupingOptions = wrapOption(grouping)
export const sourceOptions = wrapLabel(source)
export const exportDomesticOptions = wrapLabel(exportDomestic)