import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const dateCell = (record, format='DD-MM-YYYY', spanClassName = '') => {
	let value = record;	
	if(record && record.hasOwnProperty('value'))
		value = record.value;	
	return (
		<span className={spanClassName}>{(value && value != 'N/A') ? dayjs(value, format).format('DD-MM-YYYY') : 'N/A'}</span>
	)
}