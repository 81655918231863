export const rolesOptions = [
  {
    "label": "Admin",
    "value": "admin",
    "requiredRole": "admin"
  },
  {
    "label": "Label Admin",
    "value": "label_admin"
  },
  {
    "label": "Editor",
    "value": "editor"
  },
  {
    "label": "Label User",
    "value": "label_user"
  },
  {
    "label": "Viewer",
    "value": "viewer"
  },
]