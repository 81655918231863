import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Spin } from "antd";
import { getLastUpdates } from "./lastUpdatesSlice";
import { dateTimeCell } from "../../components/DateTimeCell/DateTimeCell";
import css from "./LastUpdates.module.css";


export const LastUpdates = () => {
  const dispatch = useDispatch();

  const { lastUpdates, getLastUpdatesInProgress } =
	useSelector((state) => state?.lastUpdates) || {};

  useEffect(() => {
	dispatch(
	  getLastUpdates()
	);
  }, []);

  return ( <div className={"ibox-holder"}>
		{getLastUpdatesInProgress ? <Spin /> : <div className={css.lastUpdateRow}>
		  <div className={css.lastUpdate}>
			<h2 className={css.lastUpdateTitle}>Last Update Dates</h2>
			<span className={css.lastUpdateText}>&nbsp;</span>
		  </div>
		  <div className={css.lastUpdateItems}>
			<div className={css.lastUpdateItem}>
			  <h4 className={css.lastUpdateItemTitle}>Available Stock</h4>
			  {dateTimeCell(lastUpdates['Available Stock'], css.lastUpdateItemText)}
			</div>
			<div className={css.lastUpdateItem}>
			  <h4 className={css.lastUpdateItemTitle}>Current Orders</h4>
			  {dateTimeCell(lastUpdates['Orders'], css.lastUpdateItemText)}
			</div>
			<div className={css.lastUpdateItem}>
			  <h4 className={css.lastUpdateItemTitle}>Goods In</h4>
			  {dateTimeCell(lastUpdates['Goods In'], css.lastUpdateItemText)}
			</div>
			<div className={css.lastUpdateItem}>
			  <h4 className={css.lastUpdateItemTitle}>Sales</h4>
			  {dateTimeCell(lastUpdates['Sales'],  css.lastUpdateItemText)}
			</div>
		  </div>
		</div>}
	  </div> );
};
